<template>
  <div class="guide-container">
    <h1>
      How To Buy Bitcoin On Binance With Bank Transfer
    </h1>

    <br />
    <h2 class="second-colour">
      Overview
    </h2>
    <p>
      Note that this guide relates to the Website Version of Binance, not the
      mobile app version.
    </p>

    <p>
      In this simple step guide you'll be shown how to transfer money into
      Binance from your bank account, and purchase bitcoin. The examples here
      are shown in USD and GBP, however the same process can be applied to most
      currencies transferred into Binance. The basic steps are:
    </p>

    <li>Select the fiat currency you would like to buy Bitcoin with.</li>
    <li>Transfer money from your online bank account to Binance.</li>
    <li>Wait for the money to arrive in your Binanace account.</li>
    <li>Convert your fiat currency into Bitcoin.</li>

    <br />

    <h2 class="second-colour">
      Steps
    </h2>
    <p>
      1a) Login to your Binance account
      <a href="https://accounts.binance.com/en" target="_blank">here</a>. At the
      top menu click 'Buy Crypto' and select the currency you would like to use
      to pay. In the screenshot below GBP has been selected. Depending on the
      currency, the transfer options will differ slightly. Click on 'Card
      Deposit'.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyBitcoinOnBinanceWithBankTransfer/1a.png"
      alt="Binance home screen showing buy crypto menu dropdown with GBP transfer options"
    />

    <p>
      1b) If you selected USD as your currency you will see the 'Bank Deposit'
      option. Click on that. Similar options for depositing via bank transfer
      will appear for most currencies.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyBitcoinOnBinanceWithBankTransfer/1b.png"
      alt="Binance home screen showing buy crypto menu dropdown with USD transfer options"
    />

    <p>
      2a) For GBP transfers click on 'Bank transfer (Faster Payments)'. Note
      that there is a charge of 1GBP for this method. Tansfers can take a few
      minutes upto 1 business day. Click 'Continue'.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyBitcoinOnBinanceWithBankTransfer/2a.png"
      alt="Binance deposit fiat screen showing GBP transfer options"
    />

    <p>
      2b) For USD transfers, payments are sent using the SWIFT system. This can
      take 1 to 4 business days, however there is no fee. Click 'Continue'.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyBitcoinOnBinanceWithBankTransfer/2b.png"
      alt="Binance deposit fiat screen showing USD transfer options"
    />

    <p>
      3a) Enter the amount you would like to transfer to your Binance account.
      Note that you will need a minimum of 15 GBP to buy Bitcoin.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyBitcoinOnBinanceWithBankTransfer/3a.png"
      alt="Binance deposit fiat page in GBP"
    />

    <p>
      3b) If you selected USD, enter the amount you would like to transfer. Note
      that when USD is transferred into Binance, it will be converted to BUSD.
      BUSD is known as a stablecoin issued by Binance. Each BUSD token is backed
      1:1 with US dollars held in reserve. You will be able to change BUSD into
      Bitcoin. Click 'Continue'.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyBitcoinOnBinanceWithBankTransfer/3a.png"
      alt="Binance deposit fiat page in USD"
    />

    <p>
      4) If the Important Notes popup appears, have a read, and click 'Ok.'
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyBitcoinOnBinanceWithBankTransfer/4.png"
      alt="Binance important notes popup"
    />

    <p>
      5a) Next you will be shown bank details. You will need to transfer money
      from your online banking service to Binance using these details. Once you
      have started the transfer you can click 'Check balance' to be taken to the
      Fiat And Spot wallet page.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyBitcoinOnBinanceWithBankTransfer/5a.png"
      alt="Binance bank details to transfer GBP"
    />

    <p>
      5b) A similar screen will be shown for USD transfers. Again, you will need
      to use these details in your online banking service and begin the transfer
      to Binance. Once you have started the transfer you can click 'Check
      balance' to be taken to the Fiat And Spot wallet page.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyBitcoinOnBinanceWithBankTransfer/5b.png"
      alt="Binance bank details to transfer USD"
    />

    <p>
      6) Depending on the currency you selected and your location, the transfer
      can take a few minutes upto 4 business days. Once the transfer is complete
      you should reiceve a confirmation email as shown below. In the example
      here, the transfer of GBP took only a few minutes.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyBitcoinOnBinanceWithBankTransfer/6.png"
      alt="Fiat deposit successful email"
    />

    <p>
      7) In the Fiat And Spot page you can refresh your browser to see your
      updated fiat currency balance. In the screenshot below you can see the new
      total balance of £18 GBP. Click on 'Buy'.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyBitcoinOnBinanceWithBankTransfer/7.png"
      alt="Fiat And Spot screen showing new GBP balance"
    />

    <p>
      8) Select the currency you would like to spend, and the currency you would
      like to buy. In the screenshot below you can see spend GBP to buy Bitcoin
      (BTC). Enter how much you would like to spend. You will see the amount in
      crypto update in the lower field. Click 'Continue'.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyBitcoinOnBinanceWithBankTransfer/8.png"
      alt="Buy crypto with cash balance screen showing GBP to BTC"
    />

    <p>
      9) Check the details of the order. Click 'Confirm'. The price of crypto
      changes moment to moment and so you will only have a few seconds to
      confirm the order.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyBitcoinOnBinanceWithBankTransfer/9.png"
      alt="Buy crypto with cash balance confirmation order"
    />

    <p>
      10) Click 'View History'.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyBitcoinOnBinanceWithBankTransfer/10.png"
      alt="Order processing screen with egg timer"
    />

    <p>
      11) After a few minutes you should see the Successful status appear in the
      Buy Crypto History screen. Refresh the page until you see the green
      'Successul' text.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyBitcoinOnBinanceWithBankTransfer/11.png"
      alt="Buy crypto history screen"
    />

    <p>
      12) Once you've received your crypto purchase. Click on 'Wallet', 'Fiat
      And Spot'.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyBitcoinOnBinanceWithBankTransfer/12.png"
      alt="Buy crypto history screen showing wallet drop down menu with fiat and spot option"
    />

    <p>
      13) In the 'Fiat And Spot' screen scroll down to see your new Bitcoin
      balance. Under the 'BTC Value' column you can see how much your bitcoin is
      currently worth in your selected currency.
    </p>
    <p>
      Congratulations, you've now purchased Bitcoin using your selected
      currency. If you would like to see the moment to moment price of Bitcoin
      in the trading screen click on the small 'Bitcoin' text.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyBitcoinOnBinanceWithBankTransfer/13.png"
      c
      alt="Fiat and Spot page showing new BTC balance"
    />

    <p>
      14) Here you can see the realtime price change of Bitcoin compared to
      BUSD. BUSD is a 1:1 price of USD. To search for a different currency to
      compare, enter BTC in the upper right search bar. Click on the currency.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyBitcoinOnBinanceWithBankTransfer/14.png"
      alt="Bitcoin to BUSD trading screen showing current price of Bitcoin"
    />

    <p>
      15) Below you can see the price of BTC (Bitcoin) compared to GBP.
    </p>
    <img
      src="@/assets/imgs/Guides/Binance/HowToBuyBitcoinOnBinanceWithBankTransfer/15.png"
      alt="Bitcoin to GBP trading screen showing current price of Bitcoin"
    />

    <br />
    <br />


  </div>
</template>
